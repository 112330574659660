@import 'vars';

a.LinkItem-anchor, a.LinkItem-anchor:hover {
	text-decoration: none;
	color: #000;
}

.LinktItem {
	position: relative;
	transition: 0.3s;
	:hover::after {
		transition-timing-function: ease;
	}
	font-size: 16px;
	font-family: din-2014, sans-serif;
	color: #000;
}

a.LinkItem-anchor-active::after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
	height: 2px;
	background: $brand;
}

.textOnLeft .nav-link  {
	justify-content: start;
}
