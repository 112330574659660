@import 'vars';

.page-item .page-link {
    color: #868686;
    background-color: #FFF;
    font-size: 16px;
}

.page-item.active .page-link {
    z-index: 0;
    background-color: #FFF;
    color: #000;
    font-weight: bold;
  }

.page-item.active .page-link::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: $brand;
}
