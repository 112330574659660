ul.TreeSelect-list {
	list-style: none;
	padding-left: 2px;
	width: 100%;
	background-color: white;
	color: black;
}

.TreeSelect-hovered-node:hover,
.TreeSelect-node-soft-cursor {
	background-color: #90909080;
	color: black;
}

.TreeSelect-node-selected {
	background-color: #9AD8FF;
}

.TreeSelect-empty-catalog {
	font-size: 30px;
	text-align: center;
	margin-top: 25px;
}

.TreeSelect-node{
	outline: none;
	cursor: pointer;

	img {
		max-width: 30px;
		max-height: 30px;
	}
}

.TreeSelect-node-cursor {
	background-color: black;
	color: white;
}
