.AdminRoleTable-headerRow > td {
    padding-left: 0 !important;
	padding-right: 0 !important;
	vertical-align: middle !important;
}

.AdminRoleTable-roleHeader {
	user-select: none;
}

.AdminRoleTable-path {
	white-space: nowrap;
	font-weight: bold;
}

#description > .tooltip-inner {
	max-width: 250px;
}