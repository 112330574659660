.ScrollUpButton {
	display: flex;
	flex-direction: row;
	opacity: 0.95;
	height: 45px;

	.btn{
		padding-left: 5vw;
		padding-right: 5vw;
		width: auto;
		margin-bottom: 5px;
		margin-right: 5px;
		background-color: gray;
		border-color: gray;
        pointer-events: all;
	}
}