.ListSelect-root {
	padding-bottom: 20px;
}

ul.ListSelect-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

li.ListSelect-product {
	display: flex;
	margin: 0;
	padding: 5px 5px 10px 5px;
	gap: 5px;
	align-items: center;

	&:hover {
		cursor: pointer;
		background-color: #90909080;
	}

	&.ListSelect-product-selected {
		background-color: black;
		color: white;
	}
}

.ListSelect-product-image {
	width: 35px;

	img {
		width: 100%;
		height: 100%;
	}
}

.ListSelect-product-description {
	flex: 1;
	line-height: 1.1rem;
}

.ListSelect-message {
	text-align: center;
	margin-top: 25px;
}

@media (max-width: 991.5px) {
	.ListSelect-message {
		font-size: 20px;
		min-height: 150px;
	}
}

.no-margin {
	margin: 0;
}