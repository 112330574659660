.Settlements-block {
    padding: 0px 15px;
}

.Settlements-form {
    padding: 15px 0;
}

.SettlementList-block {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1440px) {
        flex-direction: column;
    }
}

.cell {
    width: 13%;
    font-size: 14px;
    white-space: unset;
}

.cell-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.SettlementList-summary {
    width: 500px;
    flex-shrink: 0;
    @media (max-width: 1440px) {
        width: 100%;
    }
}

.SettlementList-summary-table {
    width: 100%;
}

.SettlementList-main-table {
    width: 100%;
    margin-left: 10px;
    @media (max-width: 1440px) {
        margin-left: 0;
    }

    table {
        table-layout:fixed;
        td, th {
            &:nth-of-type(1) {width: 160px;}
            &:nth-of-type(2) {width: 100px;}
            &:nth-of-type(3) {width: 80px;}
            &:nth-of-type(4) {width: 140px;}
            &:nth-of-type(5) {width: 110px;}
        } 
    }
}

.SettlementList-main-table tbody {
    line-height: 2;
}

.pdf-modal-header {
    height: 65px;
}

.pdf-modal-body {
    height: 140px;
    background-color: #f5f5f5;

    .Spinner {
        margin: auto;
    }
}

.pdf-modal-footer {
    background-color: #e9e9e9;
}

.pdf-modal.fade {
    transition: none;
}

.block-item {
    padding: 1em;
    > section > div, > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0.5rem;
        > hr {
            flex-grow: 1;
            margin-right: 15px;
            margin-left: 15px;
            border: 1px dashed #ddd;
        }
    }   
}

.block-item:after{
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid #aaa;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    display: block;
    margin: auto;
}

.block-item.rotate:after{
    transform: rotate(180deg);
}

.block-item:nth-child(even){
    background-color: #eee;
}

.date-picker {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    box-shadow: none;
    -webkit-transition: border-color .15s ease-in-out;
    transition: border-color .15s ease-in-out;
    -webkit-transition: box-shadow .15s ease-in-out;
    transition: box-shadow .15s ease-in-out;
}

.date-picker:focus {
    outline: none;
    border-color: #80bdff;
    border-width: 1px;
    border-radius: 0;
    box-shadow: 0px 0px 1px 3px #bfdeff;
}