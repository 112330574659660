.settings-icon {
	margin-top: 3px;
}

.Order-table .cell-max-width-60 {
	width: 5%;
	max-width: 60px;
}

.Order-table .cell-width-140 {
	width: 140px;
}

.Order-table .cell-width-15per {
	width: 15%;
}

.Order-table .cell-width-16per {
	width: 16%;
}

.Order-table .cell-width-9per {
	width: 9%;
	max-width: 100px;
}

.Order-table thead {
	white-space: nowrap;
}

.Order-table td {
	padding: 0;
	vertical-align: middle;
	white-space: nowrap;
	.action-button {
		padding-right: 5%;
		padding-left: 5%;
	}
}

.Order-table th {
	padding-right: 0;
	padding-left: 0;
}

.modal-open .popover-visibility{
	display: none;
}

@media (max-width: 1050px) {
	.Order-table td .action-button {
		padding-right: 1.5%;
		padding-left: 1.5%;
	}		
}

.sort-block-mobile {
	justify-content: center;
	align-items: center;
	display: flex;
	margin-bottom: 15px;
}
.sort-block-mobile select {
	margin-left: 15px;
	width: auto;
}

.Order-block-item {
	padding: 1em;
}

.Order-block-item-content-left {
	padding-right: 0.5rem;
	flex-grow: 1;
	display: flex;
}
.Order-block-item-content-right {
	width: 40%;
	display: flex;
}

.Order-block-item hr {
	flex-grow: 1;
	margin: 0.5rem;
	margin-right: 5px;
	margin-left: 5px;
	border: 1px dashed #ddd;
}

.Order-block-item > section > div,
.Order-block-item > div {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding: 0.5rem;
}

.Order-block-item:after {
	content: "";
	width: 0;
	height: 0;
	border-top: 10px solid #aaa;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	display: block;
	margin: auto;
}

.Order-block-item.rotate:after {
	transform: rotate(180deg);
}

.Order-block-item:nth-child(even) {
	background-color: #eee;
}


@media(max-width: 380px) {
	.Order-block-item {
		font-size: 0.9em;
	}
}

@media (max-width: 1444px) {

	.justify-content-end .col-auto {
		padding-right: 25px;
	}
}

.TableView-modal-footer {
	background-color: #e8e8e8;
	justify-content: space-between;
	align-items: center;
}

.TableView-modal-footer.one-button {
	justify-content: right;
}

.TableView-modal-header {
	height: 53px;
}
