@import '../../vars';

.AdditionalTab-header-container {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
}

.AdditionalTab-container-element {
    align-items: baseline;
}

.AdditionalTab-container {
    max-width: 1100px;
    margin: auto;
}

.AdditionalTab-Switch {
    margin-top: 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--brand);
    border-color: var(--brand);
}
  
.custom-control-input:checked ~ .custom-control-label::after {
    background-color: $brand;
    border-color: $brand;
}