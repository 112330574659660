.statistics-avg {
	display: flex;
	gap: 10px;
	text-align: left;
	padding: 5px;
}

.statistics-day {
	text-align: left;
	border: 2px solid black;
	margin: 0 5px 20px 5px;
	padding: 5px;
}

.statistics-day-header-buttons {
	display: flex;

	button {
		flex: 1;
	}
}