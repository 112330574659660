.PromotionOrders-table {
	width: 100%;
}

.PromotionOrders-table thead {
	white-space: nowrap;
}

.PromotionOrders-table td {
	max-width: 0;
    overflow: hidden;
    white-space: nowrap;
}

.PromotionOrders-descriptionColumn {
    text-overflow: ellipsis;
	width: 50%;
}

.PromotionOrders-mobileShortDescription {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	width: 75%;
}

.PromotionOrders-block-item-content-left-up {
	padding-right: 0.5rem;
	flex-grow: 1;
	display: flex;
}

.PromotionOrders-block-item-content-left-down {
	padding-right: 0.5rem;
	flex-grow: 1;
	display: flex;
}

.PromotionOrders-block-item-content-right-up {
	width: 50%;
	display: flex;
}

.PromotionOrders-block-item-content-right-down {
	width: 50%;
	display: flex;
}

.PromotionOrder-block-item hr {
	flex-grow: 1;
	margin: 0.5rem;
	margin-right: 5px;
	margin-left: 5px;
	border: 1px dashed #ddd;
}

.PromotionOrder-block-item > section > div,
.PromotionOrder-block-item > div {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
}

@media(max-width: 406px) {
	.PromotionOrders-block-item-content-left-down {
		padding-right: 0rem;
	}

	.PromotionOrders-block-item-content-right-down {
		width: 100%;
	}
}

@media(max-width: 341px) {
	.PromotionOrders-block-item-content-right-up {
		width: 100%;
		padding-right: 0rem;
	}

	.PromotionOrders-block-item-content-left-up {
		padding-right: 0rem;
	}
}