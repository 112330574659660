@import '../../vars';

.number-input {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	input[type="text"] {
		height: 100%;
		padding: 0;
		text-align: center;
		z-index: 0;
		-webkit-appearance: textfield;
		   -moz-appearance: textfield;
				appearance: textfield;
	}

	input[type=number]::-webkit-inner-spin-button, 
	input[type=number]::-webkit-outer-spin-button { 
		-webkit-appearance: none;
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		border-radius: 0;
		padding: 0;
		background-color: $brand;
		color:  var(--table-font-color);
		font-weight: bold;
		border: 0;
	}
	
	.btn:focus {
		box-shadow: none;
	}

}