.root {
	padding: 10px;
	background-color: #ddd;
}

.hyperlinkSettings {
	background-color: #eee;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.hyperlinkSettings > div {
	padding: 10px;
}
