.sort-buttons {
	line-height: inherit;
	font-size: inherit;
	margin: 0 3px;
	vertical-align: baseline;
	.btn {
		line-height: inherit;
		font-size: inherit;
		padding: 0;
	}
	.btn-link,
	.btn-link:hover {
		color: black;
	}
}
