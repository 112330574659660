@import '../../vars';

.ButtonComponent-buttonDesktop,
.ButtonComponent-buttonMobile {
	border: none;
	height: 42px;
	border-radius: 0px;
	position: relative;
	&:focus,&:active, &:hover {
		box-shadow: none;
	}
}

.ButtonComponent-buttonMobile {
	font-size: 14px;
}

.ButtonComponent-arrow {
	overflow: hidden;
	white-space: nowrap;
	align-items: center;
	background-color: white;
	z-index: 0;
	background: inherit !important;
	color: black !important;

	&::after {
		content: '\279C';
		display: inline-block;
		font-size: 16px;
		border-top: none;
		border-left: none;
		visibility: visible;
		transform: translateX(6px) translateY(0px);
		position: absolute;	
		transition: transform 0.3s ease-in-out;
	}

	&:hover::after {
		visibility: visible;
		transform: translateX(12px) translateY(0px);
		transition: transform 0.3s ease-in-out;
		height: 50%;
	}
}

.ButtonComponent-onHoverAnimationType2-yellow-element, .ButtonComponent-onHoverAnimationType3-yellow-element {
	overflow: hidden;
	white-space: nowrap;
	align-items: center;
	transition: padding-right 0.3s ease-in-out;
	padding-right: 35px !important;
	background-color: inherit;
	z-index: 0;
	color: black;

	&::before {
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: $brand;
		height: 100%;
		transition: height 0.3s ease-in-out;
		z-index: -1;
	}

	&:hover {
		color: inherit;
		background-color: inherit;

		&::before {
			visibility: visible;
			height: 100%;
			background-color: $brand;
			transition: height 0.3s ease-in-out;
		}
	}
}

.ButtonComponent-onHoverAnimationType2-yellow-element::before {
	height: 50% !important;
}

.ButtonComponent-onHoverAnimationType1 {
	overflow: hidden;
	white-space: nowrap;
	padding: 0 16px;
	align-items: center;
	transition: padding-right 0.2s ease-in-out;
	
	&::before {
		content: '\279C';
		display: inline-block;
		font-size: 16px;
		border-top: none;
		border-left: none;
		visibility: hidden;
		transform: translateX(10px) translateY(-11px);
		position: absolute;
		right: 16px;
		top: 50%;
		transition: transform 0.2s ease-in-out;
	}
	&:hover {
		padding-right: 40px !important;
		&::before {
			visibility: visible;
			transform: translateX(0px) translateY(-11px);
		}
	}
}

.ButtonComponent-BrandColored {
	color: black;
	background-color: $brand;

	&:focus {
		background-color: $brand-light;
		color: black;
	}

	&:active, &:hover {
		background-color: $brand;
		color: black;
	}
}

.ButtonComponent-OnBlack {
	background-color: black;
	box-shadow: 0 0 0 0.3px $brand !important;
	color: $brand !important;
	border-radius: 2px;
	&:hover, &:active {
		background-color: $brand;
		color: black !important;
	}
}