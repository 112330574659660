@import 'vars';

.address-option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.address-option-label {
		.address-option-label-desc {
			color: #828486;
			font-style: italic;
		}
	}

	.address-option-controls {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 10px;

		.address-option-control {
			&:hover {
				cursor: pointer;
			}
	
			&.edit:hover {
				color: $brand;
			}
	
			&.trash:hover {
				color: red;
			}
		}
	}
}

.address-add-new {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 5px;
	width: 100%;
	padding: 8px 12px;
	user-select: none;

	&:hover {
		background-color: #deebff;
	}
}