.Settings-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	margin-bottom: 20px;
	height: 37px;
}

.Settings-spinner-margin {
	margin: 0 5px;
}

.Settings-message {
	font-style: italic;
	margin: 0px 5px 0px 0px;
	width: 5px;
}

.Settings-placeholder {
	margin: 0px 5px 0px 0px;
	width: 5px;
}

.Settings-message-mobile {
	font-style: italic;
	margin: 0px 5px 0px 0px;
	width: 55px;
}

.Settings-placeholder-mobile {
	margin: 0px 5px 0px 0px;
	width: 55px;
}