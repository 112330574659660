.flex-end {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-end;
	justify-content: center;
}

.form-control-default {
	background-image: none !important;
	border: 1px solid #ced4da !important;
}
