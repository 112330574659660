@import '../../vars.scss';

.NotificationComponent {
	position: fixed;
	top: 5px;
	width: 98%;
	padding: 15px 10px 0px 10px !important;
	min-height: 0px !important;
	border-radius: 12px !important;
	border: 1.25px solid;
	z-index: 9999;
	pointer-events: none;

	.NotificationComponentAlert{
		color: black;
		font-size: 16px !important;
	}
}

