@import '../../vars';

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.App-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: static;
}

.App-body-container {
	flex-grow: 1;
	width: 100%;
	margin-bottom: 20px;
	padding-left: calc(100vw - 100%);
}

.App-body {
	max-width: $max-app-width;
	text-align: center;
	margin: 0 auto;
}

.App-footer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000000;
	height: 5rem;
	width: 100%;
	padding-left: calc(100vw - 100%);
}

.App-footer-content {
	width: $max-app-width;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.version-number {
	padding: 0 13px;
}

.App-login-background {
	background-color: #000000;
	width: 100%;
	padding-left: calc(100vw - 100%);
	display: flex;
	position: block;
}

.App-login-bar {
	color: #FFFFFF;
	font-family: din-2014, sans-serif;
	display: flex;
	width: 1100px;
	margin: auto;
	text-align: center;
	padding: 0.5rem 1.25rem;
	align-items: center;
	justify-content: space-between;
	position: static;

	.align-items-center {
		margin-right: 20px;
	}

	.App-account-buttons.align-items-center {
		margin-right: 0px;
		margin-left: 20px;
		display: flex;
		flex-wrap: nowrap;
		width: auto;

		>a {
			margin-right: 20px;
		}
	}
}

.App-navbar, .App-navbar-expanded {
	width: 100%;
	padding-left: calc(100vw - 100%);

	.nav-link {
		padding-left: 20px;
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
	}
}

.App-navbar-expanded {
	padding-bottom: 0.5rem;
	overflow-y: auto;
	max-height: calc(100vh - 75px);
	overscroll-behavior: contain;
}

thead {
	color: $table-font-color;
}

.sub-navbar {
	margin-bottom: 20px;
}

.active-key-name {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 2px 10px;
	color: $table-font-color;
	font-size: 1.6rem;
	font-weight: 400;
	white-space: nowrap;
}

.active-key-name::after {
	content: '';
	display: block;
	width: 100%;
	height: 2.5px;
	background-color: $brand;
	position: absolute;
	bottom: 0px;
	left: 0px;
	transition: opacity 0.3s ease-in-out;
}

.App-disabled {
	display: flex;
	min-height: 60vh;
	flex-direction: column;
	justify-content: center;
}

@media (min-width: 515px) {
	.App-logo-bar {
		padding-left: 0;

		>h1 {
			font-size: 24px;
			margin-left: 1rem;
		}

		>img {
			max-width: 150px;
		}
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.55rem;
        padding-left: 0.55rem;
    }

	.App-logo-bar {
		padding-left: calc(100vw - 100%);

		>h1 {
			font-size: 32px;
			margin-left: 2rem;
		}

		>img {
			max-width: none;
		}
	}

}

@media (max-width: $max-app-width) {
	.App-body-container {
		padding-left: 0;
	}
}

@media (max-width: 1132px) {
	.App-login-background {
		padding-left: calc(100vw - 1116px);
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.App-login-bar {
		width: auto;
	}
}



@media (max-width: 992px) {
	.hide-on-medium {
		display: none;
	}

	.App-login-bar {
		>span {
			display: inline;
		}
	}

	.App-navbar, .App-navbar-expanded {
		padding-left: 0;
	}
}

@media (max-width: 600px) {
	.App-login-bar {
		.align-items-center {
			margin-right: 0px;
		}

		.App-account-buttons.align-items-center {
			margin-left: 0px;

			>a {
				margin-right: calc(20px - calc(600px - 100vw)/8.5);
			}
		}
	}
}



@media (max-width: 515px) {


	.App-login-bar {

		.align-items-center {
			padding-left: 0.3rem;
			margin-top: auto;
			margin-bottom: auto;
		}

		>span {
			margin-right: 0;
			font-size: 13px;
		}

		>button {
			font-size: 13px;
			padding: 2px 5px;
		}

		.App-account-buttons.align-items-center {
			>a {
				margin-right: 10px;
			}
		}
	}
}

@media (max-width: 350px) {

	.App-account-buttons {

		padding: 0px;
	}

	.App-login-bar {
		padding-left: calc(((100vw * 2) / 3) - (640px / 3));
		padding-right: calc(((100vw * 2) / 3) - (640px / 3));
	}

	.align-items-center {
		padding-left: 5px;
		padding-right: 5px;
		width: 110px;
	}
}

.App-navDropdown-active {
	>.nav-link {
		color: black !important;
	}
}

.App-navDropdown {

	>.nav-link {

	}
}

.App-navDropdown-show {
	background-color: $brand-light;
}

.dropdown-menu {
	display: block;
	max-height: 0px;
	visibility: hidden;
	overflow: hidden;
	background-color: black;
	border: none;
	border-radius: 0;
	padding: 0;
	transition: all 0.4s ease-in-out;
}

.show>.dropdown-menu {
	display: block;
	max-height: 700px;
	visibility: visible;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

.App-navDropdownItem {
	color: grey;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	white-space: normal;
}

.App-navDropdownItem-active {
	color: white;
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
	white-space: normal;
}

.dropdown-item:active {
	background-color: black;
}

.App-account-buttons button {
	margin: 0 5px;
}

.price-td-div {
	text-align: right;
	margin: 0 auto;
	width: fit-content;
}

.measurer {
	width: fit-content;
	position: fixed;
	top: -1000px;
	left: -1000px;
}

.App-logo-bar {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-left: 0;
	width: 100%;
	padding-right: calc(100% -100vw);
	position: sticky;
  	top: 0;
  	left: 0;
  	z-index: 20; 
	background-color: #fff;
	padding-top: 10px;
	padding-bottom: 10px;
}

.App-nav-bar-container {
	display: flex;
	align-items: center;
	flex-grow: 1;
	justify-content: flex-end;
	padding-right: 250px;
}

.App-nav-bar-img-text {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 250px;

}

h1.dev-server-message {
	color: white;
	background-color: red;
	padding: 4px 3px;
	border-radius: 2px;
	font-weight: 300;
	font-size: 32px;
	margin: auto;
	margin-left: 1rem;
}

.container {
	max-width: 1920px;
}

.App-navbar-container {
	max-width: 1100px;
	>h1 {
		font-size: 28px;
		font-weight: 300;
		padding: 4px 3px;
		margin: auto;
		margin-left: 1rem;
	}
}

.App-navbar-toggle {
	margin-left: auto;
	color: black;
	cursor: pointer;
	display: flex;
  	flex-direction: column;
  	align-items: center;
  	cursor: pointer;
  	padding-right: 20px;
}

.App-navbar-toggle .icon {
	font-size: 24px;
}
  
.App-navbar-toggle .menu-text {
	font-size: 12px;
	color: #333;
}

.dropdown-toggle::after {
	margin-left: auto;
	margin-right: 20px;
}

.App-Mobile-Navigation {
	background: grey;
}

.navbar-collapse {
	flex-grow: 0 !important;
}

