@import '../../vars';

.ImageThumbnail-modal-img {
	object-fit: contain;
	display: block;
	margin: auto;
	max-width: 100%;
}

.ImageThumbnail-thumbnail {
	cursor: pointer;
}
