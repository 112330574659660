$max-login-width: 450px;

.Login {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	text-align: center;
}

.Login-info {
	height: 20px;
}

.Login-header {
	display: flex;
	flex-direction: column;
	align-items: center;

	>h1 {
		color: #e2201e;
		font-weight: 300;
		font-size: 32px;
		margin: 0 2rem;
	}
}

.Login-logo {
	margin-bottom: 3rem;
}

.Login-body {
	max-width: $max-login-width;
	width: calc(100% - 2rem);
}

.Warining-text {
	color: #e2201e;
	font-weight: bold;
	margin-bottom: 10px;
}

.mb-5 {
	text-align: center;
}

.reset-password-info {
	text-align: center;
	margin: 0 5px;
}

.Login-version {
	position: fixed;
	bottom: 1%;
	right: 1%;
	font-size: 0.875em;
	color: #9c9c9c;
}

.new-version-message{
	word-break: break-word;
	text-align: center; 
	border-radius: 0px;
	padding: 16px 12px;
	margin-bottom: 20px;
	border-radius: 0px; border: 1px solid #856404;
	background-color: #FFF3CD;

	p{
		color: #856404;
		font-family: "Cover Sans", "Open Sans", sans-serif, Arial;
		font-size: 14px;
		letter-spacing: 0.4px;
	}
}