.rdw-editor-toolbar,
.rdw-option-wrapper,
.rdw-dropdown-wrapper,
.rdw-editor-main {
    box-sizing: content-box;
    border: 1px solid #aaa !important;
}

#NewsEditor-title {
    width: 100%;
    height: 52px;
    border: 1px solid #aaa !important;
}

.rdw-editor-toolbar {
    position: sticky;
    top: 0;
    z-index: 999;
}

.rdw-editor-main {
    min-height: 300px;
    padding: 0.75rem 1.25rem;
}

.public-DraftStyleDefault-block {
    margin: 0 !important;
}

.rdw-link-modal {
    height: auto !important;
}

.rdw-image-modal-upload-option {
    position: relative;
}

.rdw-image-modal-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.rdw-image-modal-upload-option {
    padding: 0 !important;
}

.rdw-image-modal-upload-option-label {
    margin-bottom: 0 !important;
}

.rdw-image-modal-url-input,
.rdw-image-modal-alt-input,
.rdw-image-modal-size-input,
.rdw-link-modal-input {
    border-color: #aaa !important;
}

.rdw-option-wrapper > img {
    width: auto !important;
}

.ImageLayout-maxSize-error {
    background-color: rgb(255, 215, 215);
    color: red;
    border: 1px dashed red;
    padding: 10px;
    margin: 10px;
}

.dateError-message {
    color: #dc3545;
    font-size: 80%;
}

.NewsEditor-date {
    height: auto;
    margin-bottom: 0;
}

.NewsEditor-dateStart label {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(50% - 19px - 1rem);
}

.NewsEditor-dateStart .react-datepicker-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
}

.NewsEditor-content {
    margin-top: 1rem;
}

.NewsEditor-date-picker,
.NewsEditor-date-picker-error {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    box-shadow: none;
    transition: box-shadow .15s ease-in-out;
}

.NewsEditor-date-picker {
    border: 1px solid #ced4da;
}

.NewsEditor-date-picker-error {
    border: 1px solid #ff8080;
}

.NewsEditor-date-picker:focus,
.NewsEditor-date-picker-error:focus {
    outline: none;
    border-width: 1px;
    border-radius: 0;
}

.NewsEditor-date-picker:focus {
    border-color: #80bdff;
    box-shadow: 0px 0px 1px 3px #bfdeff;
}

.NewsEditor-date-picker-error:focus {
    border-color: #ff8080;
    box-shadow: 0px 0px 1px 3px #ffbfbf;
}

.react-datepicker-popper {
    z-index: 1000 !important;
}