.BarChart {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	gap: 5px;
	padding: 5px;
	font-size: 0.75rem;
}

.BarChart-element {
	flex: 1;
	display: flex;
	gap: 5px;
}