.block-item {
	padding: 1em;

	>section>div,
	>div {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0.5rem;

		>hr {
			flex-grow: 1;
			margin-right: 15px;
			margin-left: 15px;
			border: 1px dashed #ddd;
		}
	}
}

.block-trader-mobile {
	div {
		display: flex;
		justify-content: space-between;
	}
	.block-trader-mobile-switch {
		$switch-size: 4rem;
		label {
			width: $switch-size*(2/3);
			&::before {
				position: relative;
				transform: translateY(-40%);
				width: $switch-size;
				height: $switch-size/2;
				border-radius: $switch-size;
			}
			&::after {
				transform: translateY(calc(-40% - 2px));
				width: calc((#{$switch-size}/2) - 4px);
				height: calc((#{$switch-size}/2) - 4px);
				border-radius: $switch-size;
			}
		}
		.custom-control-input:checked ~ .custom-control-label::after {
			transform: translate(calc(#{$switch-size/2}), calc(-40% - 2px));
		}
	}
}

.block-item:after {
	content: "";
	width: 0;
	height: 0;
	border-top: 10px solid #aaa;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	display: block;
	margin: auto;
}

.block-item.rotate:after {
	transform: rotate(180deg);
}

.block-item:nth-child(even) {
	background-color: #eee;
}

.nav-link{
	display: flex;  
	align-items: center;
	justify-content: center;
}