@import '../../vars';

.CartTable-similarProductsTable {
	font-size: $secondary-font;

	tr {
		height: 40px;
	}
	
	td {
		vertical-align: middle;
	}

	thead {
		th {
			text-align: center;
		}
	}

    .CartTable-group {
        .CartTable-expandedTr {
            border-right: 2px solid  $brand-light;
            border-left: 2px solid  $brand-light;

            &.overgroup-expand {
                border-right: 2px solid  $brand;
                border-left: 2px solid  $brand;
                color: $table-font-color;
            }
        }
    }
	
	.CartTable-item {
		width: 100%;
	}
	
	.CartTable-expandedTr {
		background-color: #dddddd;
		border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
		height: 30px;
		
		&.additionalExpandedTr {
			background-color: rgb(255, 243, 195);
		}
		
	}

	.CartTable-productTr {
		&.odd {
			background-color: #efefef;
		}
	}

	.additionalOvergroupTr {
		background-color: $brand-bright;
	}

	.overgroup-expand {
		font-weight: 700;
		border-bottom: 3px rgba(0, 0, 0, 0.1) solid;
		background-color: $brand;
	}

	.form-check-input {
		margin-top: 0;
		padding: 0;
	}

	.form-check {
		display: flex;
		justify-content: center;
	}

	.foil-td {
		text-align: center;
	}

}

.CartTable-summaryElemHeaderData .number-input {
	height: 40px;
	width: 60%;
	margin-left: auto;
	margin-right: auto;
	justify-content: center;

	input {
		min-width: 40px;
		width: 60%;	
	}
	.btn {
		width: 20%;
		min-width: 40px;
	}
}

.CartTable-similarProductsTable .number-input, .CartTable-cartProductElem .number-input {
	height: 25px;
	width: 100px;
	margin-right: auto;
    margin-left: auto;
    justify-content: center;
	
	input {
		min-width: 20px;
		font-size: 90%;
	}
	.btn {
		min-width: 25px;
	}
}

.CartTable-similarProductsTable .edit-td {
	padding: 2px 0;
	margin: 0;
	text-align: center;
}

.CartTable-similarProductsTable .value-td {
	padding-right: 0.5rem;
	margin: 0;
	text-align: center;
}

.CartTable-expand-buttons {
	text-align: right;

	.btn:focus, .btn-primary:focus {
		box-shadow: none;
		outline: none;
	}
}

.CartTable-pageUpButton-box {
	position: sticky;
	bottom: 0;
	pointer-events: none;
	margin-top: 10px;

	.btn {
		pointer-events: all;
	}
}

.CartTable-pageUpButton {
	display: flex;
	flex-direction: row;
	opacity: 0.85;

	.btn {
		width: 40%;
		margin: auto;
		margin-bottom: 0.5rem;
		background-color: gray;
		border-color: gray;

		display: flex;
		justify-content: center;
		align-content: center;
		font-size: 150%
	}
}

@media(max-width: 995px) {
	.CartTable-container {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}
	.CartTable-container-left {
		display: flex;
		width: 35%;
	}
	.CartTable-container-right {
		display: flex;
		width: 65%;
	}
	.CartTable-assortAdditElem {
		background-color: $brand-light;
		height: auto;
		display: flex;
		justify-content: space-between;
		padding: 8px;
		align-items: center;
		border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

		&.additional-item {
			background-color: rgb(255, 243, 195);
		}
	}
	.CartTable-productGroup {
		background-color: $brand;
		color: $table-font-color;
	}
	.CartTable-additionalAssortmentContainer {
		background-color: #fee8a9;
	}
	.CartTable-collapseSummary {
		background-color: #f1f1f1;
		width: 100%;
		text-align: center;

		.row .col {
			padding: 5px;
		}
	}
	.CartTable-collapseSummary .CartTable-collapseHeader {
		background-color: #ffeab1;
		height: auto;
		text-align: center;
		padding: 10px;
		display: flex;
		justify-content: space-between;
	}
	.CartTable-cartProductElem {
		background-color: #eee;
		text-align: center;
	}
	.CartTable-cartProductElem > section > div > div > hr {
		flex-grow: 1;
		margin: 15px !important;
		border: 1px dashed #ddd;
	}
	.CartTable-cartProductElem:nth-child(odd),
	.CartTable-addProductCollapse:nth-child(odd) {
		background-color: #fff !important;
	}
	.CartTable-summaryElemHeader {
		text-align: center;
		flex-direction: column;
		padding: 0;
	}
	.CartTable-summaryElemHeaderData {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		div {
			flex: 1;
		}

	}
	.CartTable-SimilarElem {
		display: flex;
		justify-content: space-around !important;
		padding-bottom: 15px;
	}
	.CartTable-SummaryCateg {
		background-color: $brand;
		text-align: center;
		padding: 10px;
	}
	.CartTable-additions {
		align-items: center;

		.form-check {
			margin-left: 15px;
		}

		input {
			margin-top: 0;
		}
	}

	.CartTable-empty-info {
		width: 300px;
		height: 100px;
		justify-content: center;
		align-items: center;
		background-color: #e4e4e4;
	}

	.CartTable-expand-buttons {
		display: flex;
		margin-right: 15px;
		margin-left: 15px;

		
		button {
			flex: 1;
		}
	}
}

@media(max-width: 392px) {
	.CartTable-expand-buttons {
		button {
			min-height: 50px;
		}
	}
}

.manage-cart-icon-edit:hover {
	color: $brand;
}

.manage-cart-icon-delete {
	color: red;
}

.manage-cart-icon-edit, .manage-cart-icon-delete {
	cursor: pointer;
}