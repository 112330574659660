@import '../../../vars';

/* <- AssortmentSelection-MainGrid -> */
.AssortmentSelection-MainGrid {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1 1;
}

.AssortmentSelection-BasicView-ProductDesc {
	text-align: left;
}

/* <- AssortmentSelection-tree -> */
.AssortmentSelection-tree {
	border: 1px solid #c0c0c0;
	overflow-y: auto;
	position: relative;
	text-align: left;
}

/* <- AssortmentSelection-picture-info -> */
.AssortmentSelection-picture-info {
	padding: 15px;
	text-align: center;
}

/* <- AssortmentSelection-CardHeader -> */
.AssortmentSelection-CardHeader {
	display: flex;
	justify-content: space-between;
	text-align: right;
}

.AssortmentSelection-CardHeaderTop-cartIndicator {
	display: flex;
	justify-content: flex-end;
}

.AssortmentSelection-CardHeaderTop {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.AssortmentSelection-CardHeaderRight {
	text-align: right;
}

.AssortmentSelection-CardHeaderRight-icons {
	text-align: left;
	padding-top: 5px;
}

.AssortmentSelection-cartMessage {
	margin: 0 15px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	background-color: rgba(0, 200, 0, 0.10);
	color: rgb(0, 125, 0);
	border: 1px solid rgb(0, 200, 0);
	transform: translateY(50%);
	opacity: 0;
	transition: transform .35s, opacity .5s;

	&.show {
		opacity: 1;
		transform: translateY(-3px);
	}
}

.AssortmentSelection-CardHeaderNav {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-width: 570px;
	a {
		color: grey;
		&:hover {
			color: black;
		}
	}
}

.settings-icon {
	align-content: end;
	&:hover {
		cursor: pointer;
	}
}

/* <- NewOrder-cardIcon -> */
.AssortmentSelection-cartIcon {
	vertical-align: unset;
}

.AssortmentSelection-cartIcon:hover {
	cursor: pointer;
}

.AssortmentSelection-cartLeft {
	padding-right: 10px;
	min-height: 72px;
	display: flex;
	flex-direction: column;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
}

.spinner-border{
	margin: 19px 0px;
}

/* <- AssortmentSelection-border -> */
.AssortmentSelection-border {
	border-left: 1px solid #909090;
	display: inline-block;
	padding-left: 5px;
	margin-left: 5px;
	height: 45px;
	text-align: left;
	white-space: nowrap;
}

.AssortmentSelection-border-desktop {
	border-left: 1px solid #909090;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 10px;
	white-space: nowrap;
}

.AssortmentSelection-itemsCount {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: calc(100% - 4px);
	font-size: 0.85rem;
	border: 2px solid $brand;
	border-radius: 50%;
	text-align: center;
	background-color: $brand;
}

.AssortmentSelection-itemsCount-value {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: bold
}

/* <- AssortmentSelection-systemHeader -> */
.AssortmentSelection-systemHeader {
	display: flex;
	flex-wrap: wrap;

	>div {
		margin-right: 10px;

		>b {
			margin: 0 5px;
		}
	}
}

.AssortmentSelection-systemHeader-mobile {
	width: 100%;

	.AssortmentSelection-systemHeader {
		flex-direction: column;
		text-align: left;
	}
}

.AssortmentSelection-BasicView-empty-catalog {
	font-size: 50px;
	width: 100%;
	padding: 100px;
}

.AssortmentSelection-searchMode-toggleButton {
	padding: 10px 7px;
	background-color: white;
	color: black;
	border: 1px solid black;
	font-size: 0.75rem;
	display: flex;
	justify-content: center;
	border-radius: 0px;
}

/* MEDIA */

@media (min-width: 992px) {
	.AssortmentSelection-basicView {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 10px 20px;
		align-content: flex-start;

		.AssortmentSelection-BasicView-ProductDesc {
			grid-row: 2;
			grid-column: 1;
		}

		.AssortmentSelection-addProduct-container {
			grid-row: 2;
			grid-column: 2;

			.ProductSelection-addProduct {
				height: 100%;
			}
		}

		.AssortmentSelection-BasicView-header {
			grid-row: 1;
			grid-column-start: 1;
			grid-column-end: 3;
		}

		.AssortmentSelection-similarProductsTable-container {
			grid-row: 3;
			grid-column-start: 1;
			grid-column-end: 3;
		}

	}

	.AssortmentSelection-basicView>.NewOrder-button {
		grid-row: 4;
		grid-column-start: 1;
		grid-column-end: 3;
		width: fit-content;
		justify-self: center;
	}

	.AssortmentSelection-advancedView {
		display: grid;
		align-items: stretch;
		grid-template-rows: 40px 300px fit-content(1em);
		grid-template-columns: 2fr 3fr;
		grid-gap: 10px 20px;

		.AssortmentSelection-SearchBar {
			grid-row: 1;
			grid-column: 1;
			display: flex;
			gap: 3px;
			margin: 0;
			position: relative;

			input {
				flex: 3;
				height: 100%;
			}
		}

		.AssortmentSelection-tree {
			grid-row-start: 2;
			grid-row-end: 4;
			grid-column: 1;
			min-height: 650px;
		}

		.AssortmentSelection-AdvancedView-Title {
			grid-row: 1;
			grid-column: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 5px;

			h2 {
				margin-bottom: 0;
			}

		}

		.AssortmentSelection-AdvancedView-ProductDescription {
			grid-row: 2;
			grid-column: 2;
		}

		.AssortmentSelection-addProduct-container {
			grid-row: 3;
			grid-column: 2;
		}

		.AssortmentSelection-similarProductsTable-container {
			grid-row: 4;
			grid-column-start: 1;
			grid-column-end: 3;
		}

		.AssortmentSelection-searchMode {
			flex: 1;
			height: 100%;
		}
	}
}

@media (max-width: 991.5px) {

	/* <- AssortmentSelection-MainGrid -> */
	.AssortmentSelection-MainGrid {
		flex-direction: column;
	}

	.AssortmentSelection-BasicView-ProductDesc {
		width: 100%;
	}

	/* <- AssortmentSelection-tree -> */
	.AssortmentSelection-treeProduct {
		width: 100%;
		padding: 0;
	}

	/* <- AssortmentSelection-buttonBack -> */
	.AssortmentSelection-buttonBack {
		margin-bottom: 10px;
		border: none;
		border-radius: 0 !important;
		width: 70%;
		height: 42px;
		font-size: 11px;
	}

	/* <- AssortmentSelection-CardHeader -> */
	.AssortmentSelection-CardHeader {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.AssortmentSelection-CardHeaderTop {
		width: 30%;
	}

	/* <- AssortmentSelection-cartIcon -> */
	.AssortmentSelection-cartIcon {
		float: right;
		margin-bottom: -45px;
	}

	/* <- AssortmentSelection-border -> */
	.AssortmentSelection-border {
		width: auto;
		height: auto;
		font-size: '15px';
		border: solid $brand;
		border-radius: 50%;
		margin-top: -40px;
		margin-right: -5px;
		padding-top: -2px;
		padding-right: 3px;
		text-align: center;
		background-color: $brand;
	}

	/* <- AssortmentSelection-systemHeader -> */
	.AssortmentSelection-systemHeader {
		padding-bottom: 15px;
	}

	/* <- AssortmentSelection-searching -> */
	.AssortmentSelection-searching {
		display: flex;
		flex-direction: column;
		text-align: left;
		margin-right: 10px;
		padding-right: 10px;
	}

	.AssortmentSelection-searching .nav-link.active {
		background-color: #e9ecef !important;
		color: black !important;
	}

	.AssortmentSelection-searchMode {
		width: 100%;
		padding:  0 0 5px 0;
	}

	.AssortmentSelection-SearchBar {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1001;
		padding: 10px 0;
		border-bottom: 1px solid #c0c0c0;
	}
}

.AssortmentSelection-navigation-info{
	position: absolute;
	height: 100%;
	aspect-ratio: 1/1;
    right: -45px;

	transition: opacity 0.5s linear;
	-webkit-transition: opacity 0.5s linear;
  	-moz-transition: opacity 0.5s linear;
  	-ms-transition: opacity 0.5s linear;
  	-o-transition: opacity 0.5s linear;
	
	div {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
	}
}

.AssortmentSelection-navigation-info:hover{
	opacity: 0.4;
}

.popover {
	max-width: none;
}

.popover-body {
	display: inline-grid;
	grid-template-columns: 175px 350px;
	grid-auto-rows: auto;
	width: max-content;
	grid-column-gap: 30px;
	grid-row-gap: 5px;

	.key-group{
		display: flex;
		flex-direction: row;
		grid-column: 1;
		justify-content: center;
		align-items: center;
		
		.key-box {
			margin-left: 5px;
		}
	}

	.key-description {
		grid-column: 2;
		align-self: center;
		font-family: 'din-2014', Roboto Condensed, sans-serif;
	}
}

.key-box {
	display: flex;
	justify-content: center;
	text-transform: capitalize;
	font-family: 'din-2014', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

	border-width: 1px 1px 3px 1px;
	border-style: solid;
	border-color: #e0e0e0;
	border-bottom-color: #dbdbdb;
	border-radius: 5px;
	background: #fbfbfb;
	background: -webkit-linear-gradient(top,#fbfbfb 0,#f3f3f3 100%);
	background: linear-gradient(to bottom,#fbfbfb 0,#f3f3f3 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fbfbfb', endColorstr='#f3f3f3', GradientType=0);
	text-shadow: 1px 1px #fff;
	padding: 2px 5px;
	height: fit-content;
	width: fit-content;
	min-width: 25px;
	min-height: 30px;
}

.AssortmentSelection-CardHeaderNav-isActive {
	color: black !important;
}

.AssortmentSelection-CardHeaderNav-isActive::after {
	content: '';
	display: block;
	width: 100%;
	height: 3px;
	background-color: $brand;
	position: relative;
	bottom: 3px;
	left: 0px;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.nav-tabs {
	background-color: 0 !important;

	.nav-link {
		border: 0px;
	}

	.nav-link.active {
		background-color: inherit;
		color: black;
	}
}

.nav-tabs:hover .nav-link:hover {
	background-color: 0 !important;
	border-color: 0 !important;
}

.breadcrumb {
    border-radius: 0px !important;
}

.breadcrumb-item.active {
	color: grey;
}

.breadcrumb-item > a {
	color: black;
}